import {
    useState,
    useEffect,
    useRef,
    useDebugValue
} from "react";
import {
    IIndexable
} from "./Translation";
import type {
    RootState
} from "../redux/configureStore";
import {
    useSelector
} from "react-redux";

import { manipulateDataInterface, manipulateDataStoreInterface } from "../redux/manipulateDataSlice";
export interface groupMappingDetails {
    prop: string;
    mappedTo: string;
}
export interface groupedMapping {
    [key: string | number]: groupMappingDetails[]
    // 
}

export interface wrapperReturnOutput {
    outputPath: string;
    value: string;
    indexes: number[];
    action: string;
}

export interface recursiveFunInput {
    mappings: groupedMapping;
    partialInputData: IIndexable;
    outPutData: IIndexable;
    indexNumber: number[];
}

export interface inputCommand {
    type: string;
    value: string;
}

export interface command {
    type: string;
    necessaryVariable: IIndexable[];
    inputs: inputCommand[];
}


export interface getManipulateDataInterface {
    manipulate: manipulateDataInterface;
    objectData: IIndexable;
    manipulateTree: IIndexable;
}



const Test: React.FC = () => {
    const [inputData, setInputData] = useState < IIndexable > ();
    const [outputData, setOutputData] = useState < IIndexable > ();
    const outputJsonHTML = useRef < HTMLTextAreaElement > (null);
    const inputJsonHTML = useRef < HTMLTextAreaElement > (null);
    const translationData = useSelector((state: RootState) => state.objectData)
    const replacePropData = useSelector((state: RootState) => state.porpertyReplaceDataList)
    useDebugValue([inputData, outputData, translationData]);


    const manipulateList = useSelector((state: RootState) => state.manipulateDataList);

    const manipulateTree: IIndexable = {}



    // Inside Map Set

    // Loop over the array
    // get the values
    // manipulate
    // and set the new value inside the loop

    // Outside Map Set

    // Loop over the array
    // get the values
    // manipulate
    // and set the new value outside the loop
    const setArrayData = (input:{ data: IIndexable[], path: string, inputData:[]}) => {
        let {data, path, inputData} = input
        let pathArray =  path.split(',')
        for(let i=0; i< data.length; i++) {
            let currentData = data[i]
            for( let j=0; j< pathArray.length; j++ ) {
                if (j === (pathArray.length-1)) {
                    currentData[pathArray[j]] = inputData[i]
                    break
                }
                if (pathArray[j] in currentData) {
                    currentData = currentData[pathArray[j]]
                }else {
                    currentData[pathArray[j]] = {}
                    currentData = currentData[pathArray[j]]
                }
            }
        }
    }


    const getSetArrayObjects = (input: {
        inputsArray: string[];manipulate: manipulateDataInterface;output: boolean;data: IIndexable[]
    }) => {
        let {
            inputsArray,
            manipulate,
            output,
            data
        } = input;
        let returnManipulatedData = [];
        //data will always array
        for (let index = 0; index < data.length; index++) {
            for (let i = 0; i < inputsArray.length; i++) {
                let currentData = data[index];
                let pathArray = inputsArray[i].split('.')
                for (let j = 0; j < pathArray.length; j++) {
                    if (j === pathArray.length - 1) {
                        if (pathArray[i] in currentData) {
                            returnManipulatedData.push(currentData[pathArray[i]])
                        } else {
                            let parentPath = manipulate.parentKey
                            let currentKey = pathArray.join('.')
                            let currentPath = parentPath + '.' + currentKey
                            if (currentPath in manipulateList) {
                                let newManipulate = manipulateList[currentPath]

                                let result = getManipulateData({
                                    manipulate: newManipulate,
                                    objectData: currentData,
                                    manipulateTree
                                }) // always return array
                                
                                returnManipulatedData.push(result)
                            }
                        }
                        break;
                    }
                    if (pathArray[i] in currentData) {
                        currentData = currentData[pathArray[i]]
                    } else {
                        //It shouldn't be a problem as it should always be there
                    }
                }
                // if manipulate call get manipulate data again -> it will decide how it will handle
            }
            console.log('what is index', index)
            console.log('what is each data', data[index])
        }
        return returnManipulatedData
    }


    const getManipulateData = ({
        manipulate,
        objectData,
        manipulateTree
    }: getManipulateDataInterface): any => {
        const manipulateFunctions: IIndexable = {
            "stringAdd": (input: string) => {
                console.log("String Add is called", input)
            },
            "collectionAdd": (input: []) => {
                console.log("collection Add is called", input)
                let returnArray:IIndexable[] = []
                for(let i=0; i<input.length; i++){
                    returnArray = returnArray.concat(input[i])
                }
                return returnArray
            },
            "intAdd": (input: number[]) => {
                console.log(input);
                return input.reduce((a, b) => a + b, 0)
            },
            "collectionToString": () => {
                console.log("collectionToString is called")
            },
            "numAdd": () => {
                console.log("num Add is called")
            },
            "stringSplit": () => {
                console.log("String split is called")
            },
        }
        const {
            command
        } = manipulate;
        const {
            inputs,
            necessaryVariable,
            type
        } = command
        let currentData: IIndexable = objectData;
        let currentManipulateTree = manipulateTree;
        // manipulateTree if there is not 
        let manipulatedReturnData 
        let fetchedInputs = [];
        for (let i = 0; i < inputs.length; i++) {
            currentData = objectData;
            let parentKey = manipulate.parentKey;
            let parentKeyAddArray = Array.isArray(objectData)?parentKey + '.[]' : parentKey;


            let pathArray = inputs[i].value.split('.');
            for (let j = 0; j < pathArray.length; j++) {
                if (j === (pathArray.length - 1)) {
                    if (pathArray[j] in currentData) {
                        fetchedInputs.push(currentData[pathArray[j]]) // get node data
                    }
                }
                let newManipulateParentPath = pathArray.slice(0,j+1);
                let newManipulate  = Object.assign( {} , manipulate )
                newManipulate.parentKey = manipulate.parentKey+newManipulateParentPath.join('.');
                if (pathArray[j] === '[]' && Array.isArray(currentData)) {
                    // let currentPath = parentKey + '.' + pathArray.join('.')
                    fetchedInputs = fetchedInputs.concat(getSetArrayObjects({
                        inputsArray: pathArray.slice(j+1),
                        manipulate: newManipulate,
                        output: true,
                        data: currentData
                    }))
                    break;
                } else if (pathArray[j] in currentData) {
                    currentData = currentData[pathArray[j]]
                } else {
                    let currentPath = `${parentKeyAddArray}.${inputs[i].value}`
                    if (currentPath in manipulateList) {
                        fetchedInputs.push(getManipulateData({
                            manipulate: manipulateList[currentPath],
                            objectData: currentData,
                            manipulateTree
                        })) // get node data 
                    }
                }
            }

            if (i === (inputs.length - 1)) {
                objectData[manipulate.key] = manipulateFunctions[command.type].call(null,fetchedInputs)
            }
        }
        // if adll promise resolved ()
        // manipulateFunctions[command.type].call(null, ...fetchedInputs)
        return objectData[manipulate.key]
    }

    const handleInput = (e: React.ChangeEvent < HTMLTextAreaElement > ) => {
        if (!e.target?.value) {
            return
        }
        let inputObject = {}
        let currentString = e.target.value;
        try {
            inputObject = JSON.parse(currentString)
        } catch {
            return
        }
        if (Object.keys(inputObject).length) {
            //set all the functions 
            setInputData(inputObject)
        }
    }

    const mapTreeGenerator = (input: {mappedTo: string, targetPath: string}[]):IIndexable => {
        let groupedMapping: IIndexable = {};
        for (let i = 0; i < input.length; i++) {
            let currentTranslation = input[i];
            if (!currentTranslation?.mappedTo || !currentTranslation?.targetPath) {
                break
            }
            let pathArray = currentTranslation.targetPath.split('.');
            pathArray.shift();
            let currentMapping = groupedMapping
            for (let j = 0; j < pathArray.length; j++) {
                let cleanIndex = pathArray[j].trim()
                if (j === pathArray.length - 1) {
                    currentMapping[cleanIndex] = currentTranslation.mappedTo
                } else {
                    if (!(cleanIndex in currentMapping)) {
                        currentMapping[cleanIndex] = {}
                    }
                    currentMapping = currentMapping[cleanIndex]
                }
            }
        }
        return groupedMapping
    }

    function handleConvert() { // real mapping
        if (!inputData) {
            return
        }
        let groupedMapping = mapTreeGenerator(translationData);
        // Have to be seperate function for normal mapping tree
        
        
        console.log(groupedMapping);
        // Have to be seperate function for normal mapping tree
        //TODO:manipulateMapCreate(manipulateList) 
        console.log(manipulateTree);

        let returnOutput = returnObjectWrapper()
        let replaceData:{mappedTo: string, targetPath: string}[] = [];
        for( const property in replacePropData){
            replaceData.push({ mappedTo: property, targetPath:replacePropData[property] })
        }
        console.log("Replace Data", replaceData);
        let replaceMapping = mapTreeGenerator(replaceData);
        console.log("Replace mapping", replaceMapping);
        returnObjectCreation(inputData, returnOutput, groupedMapping, manipulateTree, '', [])
        // generateOutputJson will generate output json from translationt ree
        let returnValue = returnOutput({
            action: "read"
        })

        objectPropertyReplace(inputData,returnValue??{},replaceMapping, '', []);
        console.log("converted Object", returnValue);
        setInputData(returnValue);
        if (outputJsonHTML?.current) {
            outputJsonHTML.current.value = JSON.stringify(returnValue);
        }
        return;
    }
    /*
    [{glossary: { title: [ [ [ {hello: 1 , world: 2} ] ] ] }}, {glossary: { title: [ [ [ {hello: 3 , world: 4} ] ] ] }} ]
    {
      "[]": {
        "glossary": {
          "title": {
            "[]": {
              "[]": {
                "[]": {
                  "hello": ".[].glossary.title.[].[].hello", [{glossary: { title: [ {hello: 1 } ] }} ]
                  "world": ".[].glossary.title.[].[].world" [{glossary: { title: [ {world: 2 } ] }} ]
                }
              }
            }
          }
        }
      }
    }

    returnObject = []

    arrayIndexes = [0,0,0,0]

    currentArrayLevel = 0

    currentOupt = returnObject[0]

    */


    const convertString = Object.prototype.toString;
    const isObject = (val: any): boolean => {
        return convertString.call(val) === '[object Object]';
    }
    const isArray = (val: any): boolean => {
        return convertString.call(val) === '[object Array]';
    }
    const outputGenerate = ({
        value,
        outputPath,
        indexes
    }: Omit < wrapperReturnOutput, "action" > , output: any) => {
        console.log('what is value', value)
        let outputLocation = outputPath.split('.');
        outputLocation.shift();
        let currentArrayLevel = 0
        let currentOutput: IIndexable = output; //assigning the reference
        for (let i = 0; i < outputLocation.length; i++) {
            if (outputLocation[i] === '[]') { // Potential conflicts if root array is not consistant
                let currentArrayIndex = indexes[currentArrayLevel]; //
                if (i === 0) {
                    if (isObject(output)) {
                        output = [];
                        // Need to assign the current output after every index moves=
                        currentOutput = output;
                    }
                }

                if (i + 1 === outputLocation.length - 1) {
                    currentArrayIndex = indexes[indexes.length - 1];
                }
                if (typeof currentArrayIndex === 'undefined') {
                    currentArrayIndex = 0
                }
                if (typeof currentOutput[currentArrayIndex] === 'undefined') {
                    if (outputLocation[i + 1] === '[]') { //edge case of i === length
                        currentOutput[currentArrayIndex] = []
                    } else {
                        if (isArray(currentOutput) && !currentOutput.length) {
                            currentArrayIndex = 0
                        }
                        currentOutput[currentArrayIndex] = {}
                    }
                }

                currentOutput = currentOutput[currentArrayIndex]
                // here setting the array index
                currentArrayLevel++ //Increase the index to next level
            } else {
                // if the property exists 

                if (i === outputLocation.length - 1) {
                    if (isArray(currentOutput)) {
                        currentOutput.push()
                    } else {
                        currentOutput[outputLocation[i]] = value
                    }

                } else {
                    // [] or {}
                    if (typeof currentOutput[outputLocation[i]] === 'undefined') {
                        if (outputLocation[i + 1] === '[]') { //edge case of i === length
                            let currentArrayIndex = indexes[currentArrayLevel];
                            if (!isArray(currentOutput[outputLocation[i]])) {
                                currentOutput[outputLocation[i]] = []
                                currentOutput = currentOutput[outputLocation[i]]
                            } else {
                                currentOutput[currentArrayIndex] = []
                                currentOutput = currentOutput[currentArrayIndex]
                            }
                            // if it is array do nothing, if not create an array
                        } else {
                            if (isArray(currentOutput)) {
                                currentOutput.push({})
                                currentOutput = currentOutput[currentOutput.length - 1]
                            } else {
                                currentOutput[outputLocation[i]] = {}
                                currentOutput = currentOutput[outputLocation[i]]
                            }
                        }
                    } else {
                        currentOutput = currentOutput[outputLocation[i]]
                    }

                }
            }
        }
        console.log("what is output", output)
        return output
    }

    const returnObjectWrapper = (): (input: Partial < wrapperReturnOutput > ) => IIndexable | undefined => {
        //declare here
        let returnObject: IIndexable < any > | never[] | undefined = []

        return ({
            value,
            outputPath,
            indexes,
            action
        }: Partial < wrapperReturnOutput > ) => {
            switch (action) {
                case 'reset':
                    returnObject = [];
                    break
                case 'add':
                    console.log("Add the item to the location");
                    if( typeof value === 'undefined'){
                        value =  "null"
                    }
                    if (outputPath && indexes) {
                        outputGenerate({
                            value,
                            outputPath,
                            indexes
                        }, returnObject)
                    }
                    break
                case 'read':
                    console.log("What is read value", returnObject);
                    return returnObject
                default:
                    return returnObject;
            }

        }
    }
    function replacePropertyFunction( inputObject: IIndexable,  input:string|undefined , outputPath:string , indexes:number[]){

        let outputLocation = outputPath.split('.');
        outputLocation.shift();
        let currentArrayLevel = 0
        let currentOutput: IIndexable = inputObject; //assigning the reference
        for (let i = 0; i < outputLocation.length; i++) {
            if (outputLocation[i] === '[]') { // Potential conflicts if root array is not consistant
                let currentArrayIndex = indexes[currentArrayLevel]; //

                if (typeof currentArrayIndex === 'undefined') {
                    currentArrayIndex = 0
                }

                //Hard override to honor the array level of the last one
                if (i + 1 === outputLocation.length - 1) {
                    currentArrayIndex = indexes[indexes.length - 1];
                }

                currentOutput = currentOutput[currentArrayIndex]
                // here setting the array index
                currentArrayLevel++ //Increase the index to next level
            } else {
                // if the property exists 

                if (i === outputLocation.length - 1) {
                    let currentPropertyName = outputLocation[i]
                    let currentValue = currentOutput[currentPropertyName]
                    // input is new property
                    if (input && currentPropertyName!==input ) {
                        if (input in currentOutput) { // prop already exist avoid overwrite
                            if (isArray(currentOutput[input])) {
                                if (isArray(currentValue)){
                                    currentOutput[input].concat(currentValue); // if both array
                                }else{
                                    currentOutput[input].push(currentValue); // if new one is not array
                                }
                            }else{
                                if (isArray(currentValue)){
                                    currentOutput[input] = currentValue.push(currentOutput[input]); // if one is array
                                }else{
                                    currentOutput[input]= [ currentOutput[input], currentValue]; // if both is not array
                                }
                            }
                        }else{
                            currentOutput[input] = currentValue;
                            delete currentOutput[currentPropertyName]
                        }
                    }
                } else {
                    // [] or {}
                    if (typeof currentOutput[outputLocation[i]] === 'undefined') {
                        if (outputLocation[i + 1] === '[]') { //edge case of i === length
                            let currentArrayIndex = indexes[currentArrayLevel];
                            if (!isArray(currentOutput[outputLocation[i]])) {
                                currentOutput[outputLocation[i]] = []
                                currentOutput = currentOutput[outputLocation[i]]
                            } else {
                                currentOutput[currentArrayIndex] = []
                                currentOutput = currentOutput[currentArrayIndex]
                            }
                            // if it is array do nothing, if not create an array
                        } else {
                            if (isArray(currentOutput)) {
                                currentOutput.push({})
                                currentOutput = currentOutput[currentOutput.length - 1]
                            } else {
                                currentOutput[outputLocation[i]] = {}
                                currentOutput = currentOutput[outputLocation[i]]
                            }
                        }
                    } else {
                        currentOutput = currentOutput[outputLocation[i]]
                    }

                }
            }
        }        
    }


    function returnObjectCreation(inputObject: IIndexable, returnObject: (input: Partial < wrapperReturnOutput > ) => IIndexable | undefined, groupMapping: IIndexable, manipulateMapping: IIndexable, path: string, indexes: number[]): void {

        //ReturnObject will become the closure

        //array for loop will have function wrapper
        // recursion will return promise with 1
        console.log("Group Mapping input", groupMapping);
        console.log("returnObject creation is called indexes", indexes);
        console.log("returnObject creation is called path", path);
        let keys = Object.keys(groupMapping);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            console.log("what is key", key, groupMapping);

            if (isObject(groupMapping[key])) {
                if (key === '[]') {

                    for (let i = 0; i < inputObject.length; i++) {
                        returnObjectCreation(inputObject[i], returnObject, groupMapping[key], manipulateMapping, path + "." + key, [...indexes, i])
                    }
                    /**
                    () => {
                        for{
                            retrunValue.push(returnObjectCreation(input))
                        }
                        return retrunValue
                    }
                     */
                    // eslint-disable-next-line no-loop-func
                    // Array.prototype.map.call( inputObject , (input, index) => {  } )
                } else {
                    returnObjectCreation(inputObject[key], returnObject, groupMapping[key], (key in manipulateMapping)?manipulateMapping[key] : {}, path + "." + key, indexes)
                }
            } else {
                //The value to be assigned to
                // See if the finalVale is manipulate
                // Call manipulate Data Get Recursively
                let finalValue
                let currentPath = `${path}.${key}`;
                if (!(key in inputObject) && currentPath in manipulateList) {
                    finalValue = getManipulateData({
                        manipulate: manipulateList[currentPath],
                        objectData: inputObject,
                        manipulateTree
                    });
                } else if (key in inputObject) {
                    finalValue = inputObject[key]; // 1 [0,0,0,0]
                } else {
                    finalValue = "null"
                }

                let outputLocationString = groupMapping[key];
                console.log("output locations", outputLocationString);
                // if (!outputLocationString) {
                //     return {}
                // }
                returnObject({
                    value: finalValue,
                    outputPath: outputLocationString,
                    indexes,
                    action: "add"
                })

                console.log("one output generated", returnObject({
                    action: "read"
                }))

            }
            console.log("one return generated", returnObject)

        }
        console.log(returnObject)
    }
    function objectPropertyReplace(inputObject: IIndexable, returnObject: IIndexable, replaceMapping: IIndexable, path: string, indexes: number[]): IIndexable {

        //ReturnObject will become the closure

        //array for loop will have function wrapper
        // recursion will return promise with 1
        console.log("Replace Mapping input", replaceMapping);
        let keys = Object.keys(replaceMapping);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            console.log("what is key", key, replaceMapping);

            if (isObject(replaceMapping[key])) {
                if (key === '[]') {

                    for (let i = 0; i < inputObject.length; i++) {
                        objectPropertyReplace(inputObject[i], returnObject, replaceMapping[key], path + "." + key, [...indexes, i])
                    }
                    /**
                    () => {
                        for{
                            retrunValue.push(returnObjectCreation(input))
                        }
                        return retrunValue
                    }
                     */
                    // eslint-disable-next-line no-loop-func
                    // Array.prototype.map.call( inputObject , (input, index) => {  } )
                } else {
                    objectPropertyReplace(inputObject[key], returnObject, replaceMapping[key], path + "." + key, indexes)
                }
            } else {
                //The value to be assigned to
                // See if the finalVale is manipulate
                // Call manipulate Data Get Recursively
                let finalValue

                if (key in inputObject) {
                    finalValue = inputObject[key]; // 1 [0,0,0,0]
                } else {
                    finalValue = '';
                }

                let outputLocationString = replaceMapping[key];
                console.log("output locations", outputLocationString);
                // if (!outputLocationString) {
                //     return {}
                // }
                replacePropertyFunction( returnObject, finalValue , outputLocationString, indexes );
                // returnObject({
                //     value: finalValue,
                //     outputPath: outputLocationString,
                //     indexes,
                //     action: "add"
                // })

                // console.log("one output generated", returnObject({
                //     action: "read"
                // }))

            }
            console.log("one return generated", returnObject)

        }
        console.log(returnObject)
        return returnObject
    }


    useEffect(() => {
        if (outputJsonHTML?.current) {
            outputJsonHTML.current.value = JSON.stringify(outputData)
        }
    }, [outputData])

    return <div className = "grid grid-flow-col grid-cols-12 w-full" >
        <div className = "row-span-10 col-span-5 h-auto bg-slate-50 border-solid border-slate-80 border-2" >
        <textarea className = "w-full h-screen" ref = {inputJsonHTML} onChange = {(e) => {return handleInput(e)}} >
        </textarea> 
        </div> 
        <div className = "col-span-2" >
        <button className = "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-5" onClick = {handleConvert} >
        convert 
        </button> 
        </div> 
        <div className = "row-span-10 col-span-5 h-auto bg-slate-50 border-solid border-slate-80 border-2" >
        <textarea className = "w-full h-screen" ref = {outputJsonHTML} >
        </textarea> 
        </div> 
        </div>
}

export default Test;