import { useState, useId } from "react";
import {updateTreedataInterface}  from "../../Page/Translation";


interface IIndexable<A = any> { [key: string]: A }


interface objectDisplayProps {
  type: string;
  childValue: IIndexable<any>;
  currentKey: string|null;
  parentKey: string|null;
  listKey: string,
  manipulationList: IIndexable<any>;
  setManipulationList: React.SetStateAction<IIndexable<any>>;
  updateTree: (x:updateTreedataInterface) => void;
  updateManipulateData: (x:string, y: string) => void
}

export const RecursiveObjectDisplay: React.FC<objectDisplayProps> = ( { type, listKey, childValue, currentKey, parentKey, manipulationList, setManipulationList, updateTree, updateManipulateData } ) => {
  parentKey = (parentKey)? parentKey: '';
  const [displayChild , setDisplayChild] = useState(true);
  const [ availableMethods , setAvailableMethods ] = useState<IIndexable[]>();

  const handleRightClick = (event:React.MouseEvent)=>{
    event.preventDefault();
    event.stopPropagation();
    console.log("Right Click is called", parentKey)
    console.log("Right Click is called", childValue)
    if (!parentKey){
      return
    }

    updateTree({ path: parentKey , prop: "New Data", type})
  }

  const handleOnClick = (event:React.MouseEvent) =>  {

    switch( event.detail ){
      case 1:
        console.log("Single Click");
        break;
      case 2:
        console.log("double clickded", event.detail);
        break;
    }
    
    if (event.detail ===2){
      console.log("double clickded", event.detail);
      setDisplayChild(!displayChild);
    } 
    if(event.detail === 1){
      console.log("single click clickded", parentKey);
    }
    if (!parentKey){
      return
    }
    updateManipulateData(parentKey, type)
  }
  const handleOnDrag = (event: React.DragEvent) => {
    // console.log(event);
    // console.log(event.detail);
    // event.stopPropagation()
    // event.preventDefault()
    
    // console.log('dragged');
  }
  const dragStartData = (event: React.DragEvent) => {
    event.stopPropagation()
    if(parentKey){
      event.dataTransfer.dropEffect = "copy"
      event.dataTransfer.effectAllowed = "all";
      event.dataTransfer.setData("text", parentKey + "/$$/" + type );
      console.log("what is path", event.dataTransfer.getData("text"));
    }
  }

  const treeDropHandle = (e: React.DragEvent) => {
    e.stopPropagation()
    e.preventDefault()
    console.log("How is Tree Drop Handle Doing", parentKey);
  }
  
  const treeDragOver = (e: React.DragEvent) => {
    e.stopPropagation()
    e.preventDefault()
    console.log("How is Dragged over", parentKey);
  }

  const convertString = Object.prototype.toString;

  const recursiveDisplay = (listKey:string, childValue:IIndexable, currentKey:string|null, parentKey:string|null) => {
    console.log('recursive display is called');
    // if (Array.isArray(childValue)  ) {
    //   while(Array.isArray(childValue) && childValue.length ) {
    //     childValue = childValue[0];
    //     parentKey = parentKey + ".[]" ;
    //   }
    // }
    if ( (convertString.call(childValue) ==="[object Object]" || convertString.call(childValue) ==="[object Array]") && Object.keys(childValue).length ) {
      if (convertString.call(childValue) ==="[object Array]") {
        let newParentKey = ((parentKey) ? parentKey + ".[]" : '.[]' );
        let newChildValue = childValue[0];    
        let key= '[]'
        return <RecursiveObjectDisplay type={type} manipulationList={manipulationList} setManipulationList={setManipulationList}  listKey={newParentKey} childValue={newChildValue} currentKey={key} parentKey={newParentKey} updateManipulateData={updateManipulateData} updateTree={updateTree}/>
      }else {
        return Object.keys(childValue).map( (key) => {
          console.log("what is key", key);
          let newParentKey, newChildValue;
          newParentKey = ((parentKey) ? parentKey + "." : '.' ) + key ;
          newChildValue = childValue[key];    
          console.log("what is new child", newChildValue);
          return <RecursiveObjectDisplay type={type} listKey={newParentKey} manipulationList={manipulationList} setManipulationList={setManipulationList} childValue={newChildValue} currentKey={key} parentKey={newParentKey} updateManipulateData={updateManipulateData} updateTree={updateTree}/>
        } )
      }
    }
  }

  return (
    <ul draggable={currentKey!=='[]'} onContextMenu={handleRightClick} onDragStart={dragStartData} onDrag={handleOnDrag} onDragOver={treeDragOver} onDrop={treeDropHandle} onDropCapture={treeDropHandle} key={listKey}  className="text-left" >
      <li draggable={currentKey!=='[]'} onContextMenu={handleRightClick} onDragStart={dragStartData} onDrag={handleOnDrag} onDragOver={treeDragOver} onDrop={treeDropHandle} onDropCapture={treeDropHandle} onClick={handleOnClick}><span></span>{(currentKey)?currentKey:"Root"}</li>
      {displayChild && recursiveDisplay(listKey, childValue, currentKey, parentKey)}
    </ul>
  )
}