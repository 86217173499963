import { useEffect } from "react";

// setState  change variable
// useEffect call the function

export const useDebouncedEffect = (effect: Function, deps:[], delay: number) => {
    useEffect(() => {
        const handler = setTimeout(() => effect(), delay);

        return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps || []), delay]);
}

export const useDebounce = <T>( effect: Function, delay: number) => {
    let timer: NodeJS.Timeout;
    return <T>(value:T) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            effect(value)
        }, delay);
    }
}
