import { useState, useRef, useDebugValue, useEffect, ReactEventHandler } from "react";
// import { TranslationToolBox } from "../Components/Translation/TranslationToolBox";
import type { RootState, AppDispatch } from "../redux/configureStore";
import { useSelector, useDispatch } from "react-redux";
import {useDebounce} from "../Hooks/useDebouncedEffect";
import { add, remove } from "../redux/flattenedDataSlice";
import { add as addManipulate, remove as removeManipulate, manipulateDataInterface, manipulateDataStoreInterface,  commandInterface } from "../redux/manipulateDataSlice";
import {add as addPropReplace , remove as removePropReplace} from '../redux/propertyReplaceDataSlice'
import { RecursiveObjectDisplay } from "../Components/Tree/TreeComponent";



export interface IIndexable<T = any> { [key: string|number]: T }
export interface updateTreedataInterface {
  type: string;
  path: string;
  prop: string;
}
export interface manipulateFunction {
  functionName: string;
  functionCode: string;
}

const Translation: React.FC = () => {
  const [selectedA , setSelectedA] = useState<string>('');
  const [selectedB , setSelectedB] = useState<string>('');
  const [displayFlagA , setdisplayFlagA] = useState<boolean>(true);
  const [displayFlagB , setdisplayFlagB] = useState<boolean>(true);
  const [fieldListA, setfieldListA] = useState<string[]>([]);
  const [fieldListB, setfieldListB] = useState<string[]>([]);

  const [repPropListB, setReplacePropertyListB] = useState<string[]>([]);
  const [repPropListObject, setReplacePropertyListObject] = useState<IIndexable>({});

  
  const [parsedDataA, _setparsedDataA] = useState<IIndexable>({});
  const setparsedDataA = useDebounce(_setparsedDataA, 500)
  const [parsedDataB, setparsedDataB] = useState<IIndexable>({});

  // Manipulate
  const [modalHiddenFlag, setModalHiddenFlag] = useState<boolean>(true);
  const [inputList, setInputList] = useState<string[]>([]);
  const [inputCount, setInputCount] = useState<string[]>(['']);
  const [manipulateParentKey, setManipulateParentKey] = useState<string>('');
  const [manipulateFunction, setManipulateFunction] = useState<string>('');
  const [manipulateName, setManipulateName] = useState<string>('');
  const [beforeManipulateName, setBeforeManipulateName] = useState<string>('');
  const [manipulateType, setManipulateType] = useState<string>('');
  const [currentFunctionDataType, setCurrentFunctionDataType] = useState<IIndexable>({});
  

  useEffect( () => {
    let newList = convertStringArray(parsedDataA);
    setfieldListA(newList);
  }, [parsedDataA])

  useEffect( () => {
    let newList = convertStringArray(parsedDataB);
    setfieldListB(newList);
  }, [parsedDataB])

  const [manipulationA, setManipulationA] = useState<manipulateDataStoreInterface>({});
  const [manipulationB, setManipulationB] = useState<manipulateDataStoreInterface>({});

  const objectData = useSelector((state: RootState) => state.objectData);
  const manipulateDataList = useSelector((state: RootState) => state.manipulateDataList);
  const testReplacementPropList = useSelector((state: RootState) => state.porpertyReplaceDataList);
  const dispatch = useDispatch();
  const textAreaA = useRef<HTMLTextAreaElement>(null);
  const textAreaB = useRef<HTMLTextAreaElement>(null);

  const functionDataType:IIndexable = {
    "intAdd" : {
      inputDataType: 'number',
      outputDataType: 'number',
      necessaryVariable:[]
    },
    "stringAdd": {
      inputDataType: 'string',
      outputDataType: 'string',
      necessaryVariable: [
        {
          "Seperator": "string",
          "default" : ",",
        }
      ]
    },
    "collectionAdd": {
      inputDataType: 'any',
      outputDataType: 'array',
      necessaryVariable:[]
    }
  }

  

  const onChangeTextArea =(e: React.MouseEvent<HTMLButtonElement, MouseEvent>,ref:string) => {
      let stringValue = '';
      if (ref === 'A') {
        if (!textAreaA?.current?.value){
          return
        }
        stringValue = textAreaA.current.value
      }else{
        if (!textAreaB?.current?.value){
          return
        }
        stringValue = textAreaB.current.value
      }             

      let parsedData = {};

      try {
        parsedData = JSON.parse(stringValue);
      } catch (error) {
        console.log(error);
        console.log(stringValue);
        return
      }

      if (typeof parsedData === 'object' && Object.keys(parsedData).length) {
        if (ref === 'A' ){
          setparsedDataA(parsedData);
          setfieldListA(convertStringArray(parsedData));
          setdisplayFlagA(false)
        }
        else if (ref === 'B'){
          setparsedDataB(parsedData);
          setfieldListB(convertStringArray(parsedData, null , null , true));
          setdisplayFlagB(false)
        }    
      }
      return;
  }

  const closeModal = () => {
    setModalHiddenFlag(true)
  }

    const convertStringArray = (data: IIndexable, propsList?: string[] | null,  parentKey?: string | null, checkRepPropListB:boolean=false ): string[] => {
      if (!propsList) {
        propsList = [];
      }
      if (!parentKey) {
        parentKey = '';
      }

      
      while( isArray(data)) {
        // if (!parentKey) {

      // }
        parentKey +=".[]"
        // propsList.push(parentKey);
        data = data[0]
      }
      /**
       * data = {
       *   "hello": 1,
       *   "world": 2
       * }
       */

      //TODO:Need to find the key change signal
      const match = new RegExp('{.*}');
      for (let key in data) {
        if (checkRepPropListB && match.test(key)){
          let newReplacePropListB = [...repPropListB, parentKey + "." + key];
          setReplacePropertyListB(newReplacePropListB);
        }
        if  (isArray(data[key]) && data[key].length && (isArray(data[key][0]) || isObject(data[key][0])) ) {
          propsList.push(parentKey + "." + key);
          // propsList.push(parentKey + "." + key + '.[]');
          if ( (isArray(data[key][0]) || isObject(data[key][0])) ) {
            propsList.concat(convertStringArray(data[key][0], propsList, parentKey + "." + key + '.[]', checkRepPropListB ));
          }
        } else if (isObject(data[key])) {
          propsList.push(parentKey + "." + key);
          propsList.concat(convertStringArray(data[key], propsList, parentKey+ "." + key, checkRepPropListB));
        } else {
          propsList.push(parentKey + "." + key);
        }
      }
      return propsList;
    };

    // const returnKeyOrGoDeep = (data:any):string[] => {
    //   if (isObject(data)) {
    //     return returnKeyOrGoDeep(data)
    //   }
    //   return [];
    // }

    const handleSelectChange = (value:string, type:"A"|"B") => {
      if (type === "A")
        setSelectedA(value)
      else 
        setSelectedB(value)
    }
    const removeInputs = (index:number) => {
      inputCount.splice(index,1)
      const newInputCount = [...inputCount]
      setInputCount(newInputCount)
    }

    const addInputs = () => {
      let newInputCount = [...inputCount]
      newInputCount.push('')
      setInputCount(newInputCount)
    }

    const updateInputValue = (e:React.ChangeEvent<HTMLSelectElement> , index: number) => {
      const value = e.currentTarget.value;
      let newInput = [...inputCount];
      newInput[index] = value
      setInputCount(newInput)
    }

    const saveCommand = (e:React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.currentTarget.value;
      setManipulateFunction(value)
      let currentFunctionData = functionDataType[value];
      setCurrentFunctionDataType(currentFunctionData);
    }
    const setVariableName = (e:React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setManipulateName(value)
    }
  /*
  manipulation = [ 
    { 
      parent_key: .order,
      key: order_total,
      dataType: "string",
      inputType: "string",
      command: 
              { type: "stringAdd",
                necessaryVariable: 
                {
                    "seperator": ","
                },
                inputs: [ 
                    {type: "constant" , value: '$' },
                    {type: "node", value:".s" },
                    {type: "node", value:".t" }
                ]
              } 
    }
  ]
  */

  /**
  currentFunctionDataType =
   {
      inputDataType: 'string',
      outputDataType: 'string',
      necessaryVariable: [
        {
          "Seperator": "string",
          "default" : ",",
        }
      ]
    }
   */
    const parsedDataUpdate = (path:string, prop:string, type:string, updateType:string) => {
      let pathArray = (path[0]===".")? path.substring(1).split('.') :  path.split('.')
      let  copyObject = (type === "A")? (Array.isArray(parsedDataA))? [...parsedDataA]: Object.assign({}, parsedDataA)  :  (Array.isArray(parsedDataB))? [...parsedDataB]: Object.assign({}, parsedDataB)
      let currentObj = copyObject;
      for(let i=0; i<=pathArray.length; i++){
        if (i === pathArray.length) {
          if (updateType==='add') {
            if (Array.isArray(currentObj)) {
              currentObj[0] = {}
              currentObj[0][prop] = "test Data inserted"
            }else{
              currentObj[prop] = "test Data inserted"
            }
          }else{
            delete currentObj[prop];
          }
          break;
        }
        if (pathArray[i] === '[]') {
          if (Array.isArray(currentObj)) {
            currentObj = currentObj[0]
          }
        }else{
          if (!Array.isArray(currentObj)) {
            currentObj = currentObj[pathArray[i]]
          }
        }
      }

      if (type ==='A') {
        setparsedDataA(copyObject);

      }else{
        setparsedDataB(copyObject);
      }
    }

    const updatemanipulate= (path:string, type: string) => {
      if (type === 'A') {
        manipulateTypeA(path, type)
      }else if (type === 'B') {
        manipulateTypeB(path, type)
      }
    }

    const manipulateTypeB = (path:string , type:string) => {
      //Need to update the repPropListB
      let newReplacePropListB = [...repPropListB ];
      if (newReplacePropListB.includes(path)) {
        return
      }
      newReplacePropListB.push(path);
      setReplacePropertyListB(newReplacePropListB);
      dispatch(addPropReplace({path , value: ''}))
    }

    const manipulateTypeA = (path:string , type:string) => {
      if (!(path in manipulateDataList)) {
        return
      }
      const curentManipulate:manipulateDataInterface = manipulateDataList[path];
      const pathArray = path.split('.')
      let key =  pathArray.pop();
      if (!key) {
        key ='New Data' ;
      }
      let currentInputs = curentManipulate.command.inputs;
      setCurrentFunctionDataType({
        inputDataType: '',
        outputDataType: '',
        necessaryVariable: [],

      }) ;
      setManipulateFunction(curentManipulate.command.type);
      setInputCount(currentInputs.map((x)=>x.value))
      setManipulateParentKey(pathArray.join('.'));
      setManipulateName(key);
      setBeforeManipulateName(key);

      let  copyList = (type === "A")? [...fieldListA]  :  [...fieldListB]

      let parentKey = pathArray.join('.')

      setManipulateParentKey(parentKey);
      let newList = copyList.filter((v)=> v.startsWith(parentKey) )
      newList = newList.map((v)=>v.split(parentKey).join(''))
      newList = newList.filter((v)=>v&&v.split('[]').length<3)
      setInputList(newList)
      setManipulateType(type)
      setModalHiddenFlag(false);
    }

    const saveManipulate = () => {
      const inputs = inputCount.filter((x)=>x!=="").map((x)=>({type:'node', value:x}))
      const parentKey = manipulateParentKey
      const command =  manipulateFunction
      const manipulateDataObject :manipulateDataInterface = {
        key: manipulateName??"New Data",
        parentKey: parentKey,
        inputType: currentFunctionDataType.inputDataType ?? "string",
        outputType: currentFunctionDataType.outputDataType ?? "string",
        isManipulate: 1,
        command: {
          type: command,
          necessaryVariable: currentFunctionDataType.necessaryVariable ?? [],
          inputs }
      }
      // console.log( 'what is manipulate object', manipulateDataObject);
      if(beforeManipulateName) {
        dispatch(removeManipulate({ key:manipulateParentKey+'.'+beforeManipulateName}));
        parsedDataUpdate(manipulateDataObject.parentKey, beforeManipulateName, manipulateType, 'remove' )
      }
      dispatch(addManipulate({ key:manipulateParentKey+'.'+manipulateName , manipulate: manipulateDataObject}))
      resetInputForm();
      parsedDataUpdate(manipulateDataObject.parentKey, manipulateDataObject.key, manipulateType , 'add' )
    }

    const resetInputForm = () =>{
      setManipulateName('');
      setCurrentFunctionDataType({});
      setInputCount(['']);
      setManipulateFunction('');
      closeModal();
      setBeforeManipulateName('');
    }

    //Save Mapping
    const handleOnclickAdd = () => {
      if (!selectedA)
      return 
      if (!selectedB)
      return
      dispatch(add({ targetPath:selectedA, valueType:"string", name:selectedA, manipulate:[], mappedTo:selectedB }))
    }
    const handleRemoveClick = (index:number) => {
      return () => {
          
          dispatch(remove({index}))
      }
  }

    const convertString = Object.prototype.toString;

    const isArray = (val:any):boolean => {
      return convertString.call(val) === '[object Array]';
    }

    const isObject = (val:any):boolean => {
      return convertString.call(val) === '[object Object]';
    }

    const handleOnclickRemovePropReplace = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>, property:string) => {
      let newReplacePropListB = [...repPropListB];
      setReplacePropertyListB(newReplacePropListB.filter((p) => p!==property ));
      //need to remove the mapping as well
      let copyReplacePropertyListObject = {...repPropListObject};
      delete copyReplacePropertyListObject[property];
      setReplacePropertyListObject(copyReplacePropertyListObject);
      dispatch(removePropReplace({path:property}));
    }

    const  handlePropSelectChange = (value:string, property:string) => {
      let newRepPropListB =  {...repPropListObject};
      newRepPropListB[property] = value;
      setReplacePropertyListObject(newRepPropListB);
      dispatch(addPropReplace({ path: property ,value }));
    }

    // Add more fields by right click
    const updateParsedData = ( {type, path, prop}:updateTreedataInterface ) => { //TODO: TO add more later
      if (type === 'A') {
        updateParsedDataA({type, path, prop});
      }else{
        updateParsedDataB({type, path, prop});
      }
    }

    const updateParsedDataA = ( {type, path, prop}:updateTreedataInterface ) => {
      let pathArray = path.split('.').slice(0,-1);
      let  copyObject = (type === "A")? (Array.isArray(parsedDataA))? [...parsedDataA]: Object.assign({}, parsedDataA)  :  (Array.isArray(parsedDataB))? [...parsedDataB]: Object.assign({}, parsedDataB)
      let  copyList = (type === "A")? [...fieldListA]  :  [...fieldListB]
      // let lastElement = pathArray.pop();
      // let parentKey = (lastElement === "[]")?pathArray.join('.'): pathArray.join('.')+`.${lastElement}`;
      let parentKey = pathArray.join('.')
      setManipulateParentKey(parentKey);
      setModalHiddenFlag(false);
      let newList = copyList.filter((v)=> v.startsWith(parentKey) )
      newList = newList.map((v)=>v.split(parentKey).join(''))
      newList = newList.filter((v)=>v&&v.split('[]').length<3)
      newList = newList.filter((v:string)=>{
        return v.substring(1)
      })
      setInputList(newList)
      setManipulateType(type)
    }
    const updateParsedDataB = ({type, path, prop}:updateTreedataInterface) => {
      console.log('No update options for now');
    }
    //clear the json input object
    const handleClear = (selector: string) => {
      if (selector === "A") {
        setdisplayFlagA(true);
        setparsedDataA({});
      } else {
        setdisplayFlagB(true);
        setparsedDataB({});
      }
    }

    //Not being used now
    const handleEntryDrop = (event:React.DragEvent) => {
      event.stopPropagation();
      event.preventDefault()
    };

    //Not being used now
    const handleDropItem = (event:React.DragEvent) => {
      event.stopPropagation();
      event.preventDefault()
      console.log('handleDropItem', event.dataTransfer.getData('text'));
    };

    return <div className="flex w-full flex-grow flex-col">
        <div className="w-full">
          <button className="bg-blue-500 mt-2 hover:bg-blue-700 text-white text-xs ease-linear transition-all duration-250 font-bold py-2 px-4 rounded">save</button>
        </div>
        <div className="flex w-full  h-full">
            <div className="h-auto bg-slate-50 flex-grow flex flex-col min-w-[25%] border-r-2" >
              <div className="flex-grow h-auto overflow-auto ease-linear transition-all duration-250">
                <div className="flex absolute z-10 justify-end py-2 px-2 w-1/5">
                  {
                    (displayFlagA) ?
                      <button className="bg-blue-500 pull-right hover:bg-blue-700 text-white text-xs ease-linear transition-all duration-250 font-bold py-2 px-4 rounded"  onClick={(e)=>onChangeTextArea(e,'A')}>convert</button>
                    :
                      <button className="bg-blue-500 pull-right hover:bg-blue-700 text-white text-xs font-bold py-2 px-4 rounded ease-linear transition-all duration-250" onClick={() => {handleClear("A")}}>clear</button>
                  }
                </div>
                {
                  (displayFlagA) ?
                    <textarea className="bg-white w-full h-full" ref={textAreaA}></textarea> 
                    :
                    <div className="h-auto tree"> <RecursiveObjectDisplay type="A" manipulationList={manipulationA} updateTree={updateParsedData} setManipulationList={setManipulationA} childValue={parsedDataA} currentKey={null} parentKey={null} listKey="root" updateManipulateData={updatemanipulate}/></div>  
                }
              </div>
            </div>

            <div className="flex-grow"   onDragOver={handleEntryDrop} onDropCapture={handleDropItem}>
              <div className="w-100 flex flex-col mt-3">
                {(Object.keys(testReplacementPropList).length>0) && Object.keys(testReplacementPropList).map((property) => {
                  return <div className="flex flex-grow mt-5 mb-5 ml-4 mr-4" key={property} >
                  <select className="block py-2.5 px-0 w-full text-sm text-gray-700 bg-transparent border-0 border-b-2 border-gray-200 dark:text-gray-700 dark:border-gray-800 focus:outline-none focus:ring-0 focus:border-gray-200 peer" 
                    value={testReplacementPropList[property]} 
                    onChange={(e)=>{ handlePropSelectChange( e.target.value , property )} }>
                    <option value=''> Please select the field </option>
                    {fieldListA.length && fieldListA.map((val) => <option value={val}> {val}</option> )}
                  </select>
                  <span className="pl-3 pr-3"> name replaces </span>
                  <div className="block py-2.5 px-0 w-full text-sm text-gray-700 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-700 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer" >
                    {property}
                  </div>
                  <div className="px-2 py-2">
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-2 ml-2 text-xs rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-250" onClick={(e) =>{handleOnclickRemovePropReplace(e, property)} }>X</button>
                  </div>
                  </div>
                })}
              </div>
              <div className="w-100 flex space-x-4 mt-3 mb-5 ml-4 mr-4">
                <select className="block py-2.5 px-0 w-full text-sm text-gray-700 bg-transparent border-0 border-b-2 border-gray-200  dark:text-gray-700 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer" value={selectedA} onChange={(e)=>{ handleSelectChange( e.target.value , "A" )} }>
                  <option value=''> Please select the field </option>
                  {fieldListA.length && fieldListA.map((val) => <option value={val}> {val}</option> )}
                </select>
                <span className="">  value maps  </span>
                <select className="block py-2.5 px-0 w-full text-sm text-gray-700 bg-transparent border-0 border-b-2 border-gray-200  dark:text-gray-700 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer" value={selectedB} onChange={(e)=>{ handleSelectChange(e.target.value,"B") } } >
                  <option value=''> Please select the field </option>
                  {fieldListB.length && fieldListB.map((val) => <option value={val}> {val}</option> )}
                </select>
                <div className="px-2 py-2">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white text-xs font-bold py-3 px-2 rounded ease-linear transition-all duration-250" onClick={() =>{handleOnclickAdd()} }>Add</button>
                </div>
              </div>
              <div>
                {objectData.map( (data,i) => {
                  return <div className="row-1 flex space-x-4 pb-0 py-4 px-4 text-gray-700 dark:text-gray-700">
                    <div className="bg-gray-50 w-full flex py-3 px-2 text-sm">
                      <div className="flex-grow max-w-sm min-w-[30%] text-right">
                        {data.targetPath}
                      </div>
                      <div className="flex-grow">
                        <b> is mapped to </b>
                      </div>
                      <div className="flex-grow max-w-sm min-w-[30%] text-left">
                        {data.mappedTo}
                      </div>
                      <button onClick={handleRemoveClick(i)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 text-xs rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-250">x</button>
                    </div>

                  </div>
                })}
              </div>
              {/* <TranslationToolBox fieldListA={fieldListA} fieldListB={fieldListB} />  */}
            </div>
            <div className="h-auto bg-slate-50 flex-grow min-w-[25%] flex flex-col border-l-2 ">
              <div className="flex-grow h-auto ease-linear transition-all duration-250" >
                <div className="flex absolute z-10 justify-end py-2 px-2 w-1/5">
                  {
                      (displayFlagB) ?
                        <button className="bg-blue-500 pull-right hover:bg-blue-700 text-white text-xs ease-linear transition-all duration-250 font-bold py-2 px-4 rounded" onClick={(e)=>onChangeTextArea(e,'B')}>convert</button>
                      :
                        <button className="bg-blue-500 pull-right hover:bg-blue-700 text-white text-xs ease-linear transition-all duration-250 font-bold py-2 px-4 rounded" onClick={() => {handleClear("B")}}>clear</button>
                  }
                </div>
                {
                (displayFlagB) ?
                  <textarea className="bg-white w-full h-full" ref={textAreaB}></textarea> 
                  :
                  <div className="h-auto tree"> <RecursiveObjectDisplay type="B" manipulationList={manipulationB} updateTree={updateParsedData} setManipulationList={setManipulationB} childValue={parsedDataB} currentKey={null} parentKey={null} listKey="root" updateManipulateData={updatemanipulate}/></div>
                }
              </div>
            </div>
        </div>
        <div className={`relative z-10  ${(modalHiddenFlag)?  "hidden" : ""} ` } aria-labelledby="modal-title" role="dialog" aria-modal="true">
          {/* <!--
            Background backdrop, show/hide based on modal state.

            Entering: "ease-out duration-300"
              From: "opacity-0"
              To: "opacity-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100"
              To: "opacity-0"
          --> */}
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full m-auto items-end justify-center sm:w-1/2 w-full p-4 text-center  sm:items-center sm:p-0">
              {/* <!--
                Modal panel, show/hide based on modal state.

                Entering: "ease-out duration-300"
                  From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  To: "opacity-100 translate-y-0 sm:scale-100"
                Leaving: "ease-in duration-200"
                  From: "opacity-100 translate-y-0 sm:scale-100"
                  To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              --> */}
              <div className="relative transform overflow-hidden rounded-lg min-h-full sm:min-h-fit bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full ">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start w-full">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left flex-grow">
                      <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Add variable</h3>
                      <div className="mt-2 sm:max-w-md">
                        <label htmlFor="variable_name" className="block text-sm font-medium leading-6 text-gray-800">Variable Name</label>
                        <input 
                          onChange={setVariableName}
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                          type="text" name="variable_name" value={manipulateName}/>
                      </div>
                      <div className="mt-2 sm:max-w-md">
                        <label htmlFor="variable_name" className="block text-sm font-medium leading-6 text-gray-800">Calculation</label>
                        <select 
                          onChange={saveCommand}
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                          name="function" value={manipulateFunction}>
                          <option value="">Please select the function</option>
                          <option value="intAdd">Sum ()</option>
                          <option value="stringAdd">Concat ()</option>
                          <option value="collectionAdd">Create Collection ()</option>

                        </select>
                      </div>
                      <div className="mt-2 sm:max-w-md">
                        <label htmlFor="inputs" className="block text-sm font-medium leading-6 text-gray-800">Inputs</label>
                        {inputCount.length && inputCount.map((input, index)=><div className="mt-2 flex justify-between"><select 
                        onChange={(e)=>updateInputValue(e,index)} name="inputs" 
                        value={inputCount[index]}
                        className="block w-5/6 h-full rounded-md border-0 py-1.5 pl-2 pr-5 ring-1 ring-inset ring-gray-300 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm __web-inspector-hide-shortcut__ "
                        >
                          <option value=''> Please select the field </option>
                          {inputList.length && inputList.map((val) => <option value={val}> {val}</option>)}
                        </select>{(index )?<a onClick={()=>removeInputs(index)} className="bg-red-600 text-white py-1 px-2 rounded">remove</a>:''}</div>)}
                        <button onClick={addInputs} className="bg-green-600 text-white mt-2 py-1 px-2 rounded"> add more </button>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button type="button" onClick={saveManipulate} className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto">Save</button>
                  <button onClick={closeModal} type="button" className="mt-3 inline-flex w-full bg-red-600 text-white justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-700 sm:mt-0 sm:w-auto">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
    </div>
}

export default Translation;