import { createSlice,PayloadAction } from "@reduxjs/toolkit";

export interface ObjectDataPayload {
  targetPath: string;
  valueType:string;
  name:string;
  manipulate:Array<any>;
  mappedTo: string;
}

export interface RemoveDataPayload {
  index: number
}

const initialState: Array<ObjectDataPayload> = [];

const objectDataSlice = createSlice({
  name: 'original_values',
  initialState,
  reducers: {
    add: (state, action:PayloadAction<ObjectDataPayload>) => {
      const {
        targetPath,
        valueType,
        name,
        manipulate,
        mappedTo
      } = action.payload;
      state.push({
        targetPath,
        valueType,
        name,
        manipulate,
        mappedTo
      });
    },
    remove: (state, action: PayloadAction<RemoveDataPayload>) => {
      const {index} = action.payload
      state.splice( index, 1 )
      // return [...state]
    }
  }
})

export const {add,remove} = objectDataSlice.actions;
export default objectDataSlice.reducer;