import { createSlice } from "@reduxjs/toolkit"
import { IIndexable } from "../Page/Translation"


const initialState:IIndexable = {};

const porpertyReplaceSlice =  createSlice({
    name: 'propertyReplace',
    initialState,
    reducers: {
        add: ( state, action )=>{
            const { path, value} = action.payload;
            state[path] = value;
        },
        remove: (state, action)=>{
            console.log('remove the propertyReplace', state[action.payload.path]);
            const {path} = action.payload;
            delete state[path];
        }
    }
});

export const {add, remove} = porpertyReplaceSlice.actions
export default porpertyReplaceSlice.reducer