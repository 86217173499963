import { Outlet, Link } from "react-router-dom";

const Layout: React.FC = () => {
    return <div className="h-full flex flex-col">
        <nav className="bg-gray-800">
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="flex space-x-4">
                  <Link to="/home" className="bg-gray-800 text-white rounded-md px-3 py-2 text-sm font-medium">
                    Home
                  </Link>
                  <Link to="/translation" className="bg-gray-800 text-white rounded-md px-3 py-2 text-sm font-medium">
                    Translation
                  </Link>
                  <Link to="/test" className="bg-gray-800 text-white rounded-md px-3 py-2 text-sm font-medium">
                    Test
                  </Link>
                </div>

              </div>
            </div>
        </nav>

        <Outlet></Outlet>
    </div>
}

export default Layout;