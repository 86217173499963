import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Layout/Layout';
import Home from './Page/Home'
import Test from './Page/Test'
import Translation from './Page/Translation'
// import TranslationDrop from './Page/TranslationDrop'

import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes >
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />}>
            </Route>
            <Route path="/home" element={<Home />}>
            </Route>
            <Route path="/translation" element={<Translation />}>
            </Route>
            <Route path="/test" element={<Test />}>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
