import {
    configureStore
} from '@reduxjs/toolkit';
import objectDataReducer from './flattenedDataSlice';
import manpulationDataReducer from './manipulateDataSlice';
import propertyReplaceDataReducer from './propertyReplaceDataSlice';

export const store = configureStore({
    reducer: {
        objectData: objectDataReducer,
        manipulateDataList: manpulationDataReducer,
        porpertyReplaceDataList: propertyReplaceDataReducer,
    }
});


export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch