import {PayloadAction, createSlice} from "@reduxjs/toolkit"
import { IIndexable } from "../Page/Translation";

export interface manipulateDataInterface {
    key: string;
    parentKey: string;
    isManipulate: number;
    outputType: string;
    inputType: string;
    command: commandInterface;
}

export interface manipulateDataStoreInterface {
    [key: string] : manipulateDataInterface;
}

export interface inputInterface{
    type: string;
    value: string;
}

export interface commandInterface {
    type: string;
    necessaryVariable: IIndexable;
    inputs: inputInterface[],
}

const initialState: manipulateDataStoreInterface = {};

const manpulationDataSlice = createSlice({
    name: "manipulation",
    initialState,
    reducers: {
        add: (state, action)=>{
           const {manipulate, key} = action.payload;
           state[key]= manipulate;
        },
        remove: (state, action)=>{
            const {key} = action.payload;
            delete state[key];
         },
    }

});

export const {add, remove} = manpulationDataSlice.actions;
export  default manpulationDataSlice.reducer;